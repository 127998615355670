import React from 'react'
import "./Trainings.css"
import Showcase from './Showcase/Showcase';
import data from "../../locales/ro.json"

export default function Trainings() {
    const infoArr = data.trainings
    return (<div className='trainingsContainer'>
        <h1 className="benefitsTitle">Traininguri</h1>
        <Showcase infoArr={infoArr} indexOffset={1}/>
    </div>)
}