import React from "react";
import "./PreviousEditions.css";

export default function PrevEditionComponent({ index, title, description }) {
  return (
    <li className="prevEditionListElement">
      <div className="prevEditionContainer">
        <div className="prevEditionNumeral"><p>{index}</p></div>
        <div className="prevEditionTextContainer">
          <h3 className="prevEditionTitle">{title}</h3>
          <p className="prevEditionDescription">{description}</p>
        </div>
      </div>
      <hr></hr>
    </li>
  );
}
