import React from "react";
import "./PreviousEditions.css";
import PrevEditionComponent from "./PrevEditionComponent";
import data from "../../locales/ro.json"

export default function PreviousEditions() {
  return (
    <ul className="previousEditionsContainer">
      <PrevEditionComponent
        index={1}
        title={"Prima editie IDEA"}
        description={
          data.prevEditions[0]
        }
      />
      <PrevEditionComponent
        index={2}
        title={"A doua editie IDEA"}
        description={
          data.prevEditions[1]
        }
      />
    </ul>
  );
}
