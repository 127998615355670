import './horizontalImageScroll.css'
import Card from './Card'

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { EffectFade, EffectCoverflow, Pagination, Navigation } from 'swiper/modules';
import { useState } from 'react';

function HorizontalImageScroll({data,title})
{
    const multiplier = window.innerWidth > 759? 10: 1;
    const numberMapper = Array.from({length: data.cardImages.length * multiplier}, (_, i) => i)
    const [number, setNumber] = useState(1);
    return (
        <div>
            <h1 className='horizontalImageScrollTitle'>{title}</h1>
            <Swiper
                onSlideChange={(el) => {setNumber(el.realIndex);}}
                effect={'coverflow'}
                grabCursor={true}
                centeredSlides={true}
                loop={true}
                slidesPerView={window.innerWidth > 759? window.innerWidth / 350: 2}
                coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: data.cardImages.length * multiplier,
                }}
                spaceBetween={window.innerWidth > 759? 0:80}
                pagination={{ el: '.swiper-pagination', clickable: true }}
                navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
                clickable: true,
                disabledClass: '.swiper-button-disabled',
                }}
                modules={[EffectCoverflow, Pagination, Navigation, EffectFade]}
                className="swiper_container"
            >
                {
                    numberMapper.map((num) => 
                        <SwiperSlide>
                            <Card dataCard={{
                                cardImage: data.cardImages[num % data.cardImages.length],
                                cardTitle: data.cardTitles[num % data.cardTitles.length],
                                cardDescription: data.cardDescriptions[num % data.cardDescriptions.length]}} 
                                isActive={number === num}
                                key={num}/>
                        </SwiperSlide>
                    )
                }
                
            </Swiper>
            { window.innerWidth < 759?
            <div>
                <div className="slider-controler">
                    <div className="swiper-pagination"> </div>
                </div>
            </div> : null
            }
        </div>
    );
}

export default HorizontalImageScroll;