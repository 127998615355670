import React from 'react'
import "./LandingPage.css"
import lightbulbImg from "../../icons/dotsLightbulb.svg"
import massImg from "../../icons/dotsMass.svg"
import data from "../../locales/ro.json"
import Countdown from 'react-countdown'


export default function LandingPage() {

    
    return <div className='landingPageContainer' id='home'>
        <img src={lightbulbImg} className='landingPageLightbulb'></img>
        <img src={massImg} className='landingPageImgMass'></img>
        <h1 className='landingPageTitle'>{data.landingPage.title}</h1>
        <h2 className='landingPageSubtitle'>{data.landingPage.subtitle}</h2>
        <p className='countdownTitle'>TIMP RĂMAS</p>
        <div className='countdownSubtitle'>CHALLENGE</div>
        <div className='countdownContainer'><Countdown date={new Date("2024-05-03T23:59:59+02:00")}></Countdown></div>
        <div className='countdownSubtitle'>TRAININGURI</div>
        <div className='countdownContainer' style={{color:"var(--turcoaz)"}}><Countdown date={new Date("2024-05-10T22:00:00+02:00")}></Countdown></div>
        <button className='landingPageButton'><a href='https://forms.gle/sw2YkXgfS5c1Z2Qk9' target='_blank'>APLICĂ AICI</a></button>
    </div>
}