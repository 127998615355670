import React from "react";
import "./Benefits.css";
import Showcase from "./Showcase/Showcase";
import data from "../../locales/ro.json"

export default function Benefits() {
  const infoArr = data.benefits
  return (
    <div id="benefits">
      <h1 className="benefitsTitle">Beneficii și Oportunități</h1>
      <Showcase infoArr={infoArr} />
    </div>
  );
}
