import React from "react";
import "./Partners.css";

export default function PartnerCard({ size, logo, index, isMobile, link }) {
  const style = {};
  switch (size) {
    case "xs":
      if(isMobile)
      {style.width = "100px";
      style.height = "100px";}
      else {
        style.width = "50px";
        style.height = "50px";
      }
      break;
    case "s":
      if(isMobile)
      {style.width = "125px";
      style.height = "125px";}
      else {
        style.width = "75px";
        style.height = "75px";
      }
      break;
    case "m":
      if(isMobile)
      {style.width = "150px";
      style.height = "150px";}
      else {
        style.width = "100px";
        style.height = "100px";
      }
      break;
    case "l":
      if(isMobile)
      {style.width = "200px";
      style.height = "200px";}
      else {
        style.width = "125px";
        style.height = "125px";
      }
      break;
    default: 
      break;
  }

  return <div className="partnerCard">
    <a href={link} target="_blank"><img src={logo} style={style} className="partnerCardImg"></img></a>
  </div>;
}
