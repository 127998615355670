import React from "react";
import "./TitleCard.css";

import lightbulbIcon from "../../../icons/lightbulb.svg";

export default function TitleCard({ title }) {
  return (
    <div className="titleCard">
      <img src={lightbulbIcon} alt="lightbulb"></img>
      <h1>{title}</h1>
    </div>
  );
}
