import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./Showcase.css";
import ShowcaseElem from "./ShowcaseElem";
import arrowIcon from "../../../icons/carouselarrow.svg";

export default function Showcase({ infoArr, indexOffset=0 }) {
  const [width, setWidth] = React.useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  React.useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;
  return (
    <div className="showcaseContainer">
      <div>
        <Carousel
        emulateTouch={true}
          showThumbs={false}
          showIndicators={isMobile}
          showStatus={false}
          renderArrowPrev={(clickHandler, hasPrev, labelPrev) => (
            <button onClick={clickHandler} className="showcaseArrow" style={{position:"absolute",bottom:"0px",left:"calc(50% - 150px)"}}>
              {!isMobile && <img
                alt="prevArrow"
                style={{ height: "100px", width: "100px", transform:"scaleX(-1)" }}
                src={arrowIcon}
              />}
            </button>
          )}
          renderArrowNext={(clickHandler, hasNext, labelNext) => (
            <button onClick={clickHandler} className="showcaseArrow" style={{position:"relative",bottom:"0px",left:"calc(50% + 50px)"}}>
              {!isMobile && <img
                alt="nextArrow"
                style={{ height: "100px", width: "100px"}}
                src={arrowIcon}
              />}
            </button>
          )}
        >
          {infoArr.map((element, index) => {
            return (
              <ShowcaseElem
                image={element.img}
                title={element.title}
                content={element.content}
                orientation={(index+indexOffset) % 2 === 0 ? "left" : "right"}
              />
            );
          })}
        </Carousel>
      </div>
      {/* <div className="showcaseArrowsContainer">
        <img alt="leftArrow" src={arrowIcon}></img>
        <img alt="rightArrow"></img>
      </div> */}
    </div>
  );
}
