import React from "react";
import "./App.css";
import Showcase from "./components/benefitstrainings/Showcase/Showcase";
import Benefits from "./components/benefitstrainings/Benefits";
import Trainings from "./components/benefitstrainings/Trainings";
import Rocket from "./components/program/Rocket";
import Program from "./components/program/Program";
import Rules from "./components/rules/Rules";
import Partners from "./components/partners/Partners";
import Contact from "./components/contact/Contact";
import TitleCard from "./components/global/TitleCard/TitleCard";
import Trainers from "./components/trainers/Trainers";
import About from "./components/about/About";
import PreviousEditions from "./components/previouseditions/PreviousEditions";
import LandingPage from "./components/landingpage/LandingPage";
import Navbar from "./components/global/Navbar/Navbar";
import Footer from "./components/footer/Footer";
import HorizontalImageScroll from "./components/global/horizontalImageScroll/HorizontalImageScroll";
import teamBackground from "./icons/teamMass.svg"
import data from "./locales/ro.json"

function App() {

  const [width, setWidth] = React.useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  React.useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;
  const [hasScrolled, setHasScrolled] = React.useState(false);
  React.useEffect(() => {
    window.onscroll = () => {
      // console.log("test");
      console.log(window.scrollY);
      if (window.scrollY > 500) setHasScrolled(true);
      if (window.scrollY < 500) setHasScrolled(false);
    };
    return () => (window.onscroll = null);
  });
  return (
    <div
      className="App"
      onScroll={() => {
        setHasScrolled(true);
      }}
    >
      <Navbar scrolled={hasScrolled} isMobile={isMobile}/>
      <LandingPage />
      <TitleCard title={data.titleCards.firstTitle} />
      <Benefits />
      <Trainings />
      {/* <Trainers isMobile={isMobile} /> */}
      <TitleCard title={data.titleCards.secondTitle} />
      <About isMobile={isMobile}/>
      <PreviousEditions />

      <Partners isMobile={isMobile}/>
      <div className="programRulesContainer">
        <Program isMobile={isMobile} />
        <Rules />
      </div>
      <div id="team" style={{backgroundColor:"var(--mov)",color:"var(--alb)",paddingTop:"8rem",position:"relative",marginBottom:"-100px"}}>
        <img className="teamBackgroundImg" src={teamBackground} alt="background"></img>
      <HorizontalImageScroll
        data={data.ourTeam}
        title={"Echipa de organizare"}
      />
            </div>
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
