import React from "react";
import "./Footer.css";
import facebookIcon from "../../icons/facebookIcon.svg";
import instagramIcon from "../../icons/instagramIcon.svg";
import linkedInIcon from "../../icons/linkedInIcon.svg";
import youtubeIcon from "../../icons/youtubeIcon.svg";
import mapPinIcon from "../../icons/footerMapPin.svg";
import phoneIcon from "../../icons/footerPhone.svg";

export default function Footer() {
  return (
    <footer>
      <hr />
      <div className="footerContainer">
        <div className="footerIcons">
          <a href="https://www.facebook.com/EESTEC.LC.Bucharest/" target="_blank"><img src={facebookIcon} alt="facebook"></img></a>
          <a href="https://www.instagram.com/eestec_lc_bucharest/" target="_blank"><img src={instagramIcon} alt="instagram"></img></a>
          <a href="https://www.linkedin.com/company/eestec-bucharest/" target="_blank"><img src={linkedInIcon} alt="linkedin"></img></a>
          <a href="https://www.youtube.com/@eeStecBucuresti" target="_blank"><img src={youtubeIcon} alt="youtube"></img></a>
        </div>
        <div className="footerLocation">
          <img src={mapPinIcon} alt="Where to find us" />
          Splaiul Independenței, nr. 313, AN204bis, UNSTPB
        </div>
        <div className="footerPhone">
          <img src={phoneIcon} alt="Where to call us" /> 0752 214 570
        </div>
      </div>
    </footer>
  );
}
