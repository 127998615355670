import React from "react";
import "./Partners.css";

export default function PartnersTitle({ title, color }) {

    const style = {}
    style.backgroundColor = `var(--${color})`
  return (
    <div className="partnersTitleContainer">
      <h3>{title}</h3>
      <div style={style} className="partnersTitleSmallLine partnersTitleLine"></div>
      <div style={style} className="partnersTitleBigLine partnersTitleLine"></div>
    </div>
  );
}
