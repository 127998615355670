import React from "react";
import "./Program.css";

export default function ProgramTable({ dayArr }) {
  return (
    <div className="programTable">
      {dayArr.map((event) => {
        return (
          <div className="programLineContainer">
            <span className="programHoursContainer">
            <span className="programHour">{event.startHour}</span> -{" "}
            <span className="programHour">{event.endHour}</span>
            </span>
            <div className="programTableVertLine"></div>
            <span className="programEventName">{event.name}</span>
          </div>
        );
      })}
    </div>
  );
}
