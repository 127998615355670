import React from "react";
import "./Rules.css";
import rulesArrow from "../../icons/rulesarrow.svg"

export default function RuleComponent({
  header,
  content,
  selectedIndex,
  index,
  onClickFcn,
}) {
  console.log(index)
  console.log(selectedIndex)
  return (
    <div className="ruleLineContainer">
      <button className={`ruleHeader ${selectedIndex == index ? "selectedRule" : selectedIndex == -1 ? "" : "hideRule"}`} style={index % 2 === 0 ? {backgroundColor:"var(--turcoaz)"} : {backgroundColor:"var(--mov)"}} onClick={onClickFcn}>
        <p>{header}</p>
        <div style={{display:"flex",minHeight:"100%",alignItems:"stretch"}}>
        <img alt="read more" src={rulesArrow} className={`rulesHeaderArrow ${selectedIndex == index ?"selectedArrow" : ""}`}></img>
        <div className="rulesHeaderTriangle"></div>
        </div>
      </button>
      <div className="ruleContent">{content}</div>
    </div>
  );
}
