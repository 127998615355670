import React from "react";
import "./About.css";

import ideaLogo from "../../icons/IDEAtext.svg";
import background from "../../icons/aboutBG.png";
import data from "../../locales/ro.json"
import { Carousel } from "react-responsive-carousel";

import { useVisible } from "react-hooks-visible";

export default function About({isMobile}) {
  const [targetRef, visible] = useVisible();

  const [animate, setAnimate] = React.useState(false);
  React.useEffect(() => {
    if (visible > 0.5) {
      setAnimate(true);
    }
  }, [visible]);
  const descriptionsArr = data.about;
  return (
    <div id="about" ref={targetRef} className="aboutContainer">
      <img
        src={background}
        alt="background image"
        className="aboutFullBackground"
      ></img>
      <div
        className={`aboutCarouselContainer ${
          animate === true ? "aboutCarouselAnimate" : ""
        }`}
      >
        <Carousel emulateTouch={true} showStatus={false} showThumbs={false} showArrows={false}>
          {descriptionsArr.map((description) => {
            return <p className="aboutDescription">{description}</p>;
          })}
        </Carousel>
      </div>
      {!isMobile && <div
        className={`aboutImgContainer ${
          animate === true ? "aboutImgAnimate" : ""
        }`}
        style={{ position: "relative" }}
      >
        <img className="aboutIdeaImg" src={ideaLogo} alt="IDEA"></img>
        <div className="aboutBackground"></div>
      </div>}
    </div>
  );
}
