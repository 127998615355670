import React from "react";
import "./Navbar.css";
import navbarLogo from "../../../icons/ideaLogoNavbar.svg";
import navbarLogoMobile from "../../../icons/ideaLogoMobileNavbar.svg";
import borgirIcon from "../../../icons/borgir.svg";
import closeIcon from "../../../icons/X.svg";

export default function Navbar({ scrolled, isMobile }) {
  const [style, setStyle] = React.useState({
    backgroundColor: "rgba(63, 37, 105,0)",
  });
  // let style = { backgroundColor: "rgba(63, 37, 105,0)" };
  React.useEffect(() => {
    if (scrolled) setStyle({ backgroundColor: "rgba(63, 37, 105,0.5)" });
    else {
      setStyle({ backgroundColor: "rgba(63, 37, 105,0)" });
    }
  }, [scrolled]);

  const [isOpen, setIsOpen] = React.useState(false)
  return (
    <div>
      {!isMobile && (
        <nav className="navbarContainer" style={style}>
          <div>
            <a href="#home"><img className="navbarLogo" src={navbarLogo} alt="IDEA"></img></a>
          </div>
          <ul className="navbarButtons">
            <li className="navbarListEl">
              <a href="#benefits">Beneficii</a>
            </li>
            <li className="navbarListEl">
              <a href="#about">Despre</a>
            </li>
            <li className="navbarListEl">
              <a href="#program">Program</a>
            </li>
            <li className="navbarListEl">
              <a href="#team">Organizare</a>
            </li>
            <li className="navbarListEl">
              <a href="#contact">Contact</a>
            </li>
          </ul>
          <div>
            <button className="navbarApply"><a href='https://forms.gle/sw2YkXgfS5c1Z2Qk9' target='_blank'>Înscrie-te</a></button>
          </div>
        </nav>
      )}
      {isMobile && (
        <nav className={`mobileNavbar ${isOpen ? "navbarOpen" : ""}`}>
          <div className="mobileNavbarHeader">

          <img
            className="ideaMobileNavbar"
            src={navbarLogoMobile}
            alt="IDEA"
            style={scrolled ? isOpen ? {opacity:"100%"} : {opacity:"0%"} : {opacity:"100%"}}
          ></img>
          <button  style={style} className="buttonMobileNavbar" onClick={() => {
            setIsOpen((prevVal) => {
              return !prevVal
            })
          }}>
            <img src={isOpen ? closeIcon : borgirIcon} alt="Menu"></img>
          </button>
            </div>
          <ul className={`mobileNavbarButtons ${isOpen ? "navbarListOpen" : ""}`}>
            <li className="mobileNavbarListEl">
              <a href="#benefits">Beneficii</a>
            </li>
            <li className="mobileNavbarListEl">
              <a href="#about">Despre</a>
            </li>
            <li className="mobileNavbarListEl">
              <a href="#program">Program</a>
            </li>
            <li className="mobileNavbarListEl">
              <a href="#team">Organizare</a>
            </li>
            <li className="mobileNavbarListEl">
              <a href="#contact">Contact</a>
            </li>
          </ul>
        </nav>
      )}
    </div>
  );
}
