import { alertSuccessful, alertError } from "../global/Alerts/alerts";
import "./contact.css";

import emailjs from "@emailjs/browser";

import bgArt from "./bg-art.svg";
import { useRef } from "react";
import { SuccessToast } from "../global/Alerts/Toasts";
import useIsMobile from "../global/Hooks/ReziseWindowHook";

function Contact() {
  let isMobile = useIsMobile();
  const formdata = useRef({
    from_name: "",
    from_email: "",
    message: "",
  });

  function onSubmit(e) {
    e.preventDefault();
    emailjs
      .send(
        "service_4bunwd7",
        "template_12qu2oe",
        formdata.current,
        "BjmmvuZQoOm_-uLj-"
      )
      .then(
        () => {
          formdata.current = {};
          alertSuccessful("Mesajul a fost trimis cu succes!");
        },
        (error) => {
          alertError("Mesajul nu a putut fi trimis!");
        }
      );
  }

  return (
    <div className="contact-wrapper" id="contact">
      <SuccessToast />
      {!isMobile && <img src={bgArt} alt="bg-art" className="bg-art" />}
      <div className="contact-main">
        <form onSubmit={onSubmit} className="contact-container">
          <div className="contact-us-text"> Contactează-ne</div>
          <input
            className="contact-input"
            content={formdata.current.from_name}
            type="text"
            placeholder="Nume și prenume"
            onChange={(e) => (formdata.current.from_name = e.target.value)}
          />
          <input
            className="contact-input"
            content={formdata.current.from_email}
            type="text"
            placeholder="Adresă de email"
            onChange={(e) => (formdata.current.from_email = e.target.value)}
          />
          <textarea
            className="long-input contact-input"
            content={formdata.current.message}
            type=""
            placeholder="Mesajul tău"
            onChange={(e) => (formdata.current.message = e.target.value)}
          />
          <button className="contact-button">Trimite</button>
        </form>
        <div className="contact-info">
          <div className="contact-person">
            <div className="contact-person-photo puzzle-left">
              <img
                src="https://i.imgur.com/iYLcrk7.jpg"
                className="puzzle-foto"
              />
            </div>
            <div className="contact-person-name">Bianca-Ioana Alexe</div>
            <div className="contact-person-statute">
              {" "}
              Presedinte EESTEC Bucuresti{" "}
            </div>
            <div className="contact-person-phone"> +40 752 214 570 </div>
            <div className="contact-person-email"> bianca.alexe@eestec.ro </div>
          </div>
          <div className="contact-person">
            <div className="contact-person-photo-right puzzle-right">
              <img
                src="https://i.imgur.com/s7KBTHi.jpg"
                className="puzzle-foto"
              />
            </div>
            <div className="contact-person-name">Cîtea Cezar</div>
            <div className="contact-person-statute">
              {" "}
              Coordonator IDEA 3{" "}
            </div>
            <div className="contact-person-phone"> +40 758 572 974 </div>
            <div className="contact-person-email"> citea.cezar@eestec.ro </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
