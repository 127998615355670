import React from "react";
import "./Program.css";
import RocketIcon from "../../icons/racheta.svg";

export default function Rocket({ funcs }) {
  return (
    <div className="rocketContainer">
      <button onClick={funcs[0]}><a href="https://www.google.com/maps/dir//google+maps+biblioteca+upb/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x40b201c1c2998f79:0x1acb42f08800db7a?sa=X&ved=1t:3061&ictx=111" target="_blank">Locație</a></button>
      <button onClick={funcs[1]}>
        10
        <br />
        mai
      </button>
      <button onClick={funcs[2]}>
        11
        <br />
        mai
      </button>
      <button onClick={funcs[3]}>
        10
        <br />
        mai
      </button>
      <button onClick={funcs[4]}>
        11
        <br />
        mai
      </button>
    </div>
  );
}
