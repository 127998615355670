import './card.css'

function Card({dataCard, isActive})
{
    let cardImage = dataCard.cardImage;
    let cardTitle = dataCard.cardTitle;
    let cardDescription = dataCard.cardDescription;
    console.log(isActive)
    return (
        <div className=''>
            <img className='cardImg' src={cardImage} alt="slide_image" />
            {
            isActive?
                <div>
                    <div className='card_title'> {cardTitle}</div>
                    <div className='card_subtitle'> {cardDescription}</div>
                </div> : null
            }
        </div>
    )
}

export default Card;