import React from "react";
import "./Showcase.css";

export default function ShowcaseElem({ image, title, content, orientation }) {
  const [width, setWidth] = React.useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  React.useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;
  const isTrue = true;
  const style = {};
  if (orientation === "right") {
    style.textContainerStyle = {
      right: 0,
    };
    style.image = {
      left: "25%",
    };
    style.shadow1 = {
      left: "calc(25% - 25px)",
    };
    style.shadow2 = {
      left: "calc(25% - 50px)",
    };
  } else {
    style.textContainerStyle = {
      left: 0,
    };
    style.image = {
      right: "25%",
    };
    style.shadow1 = {
      right: "calc(25% - 25px)",
    };
    style.shadow2 = {
      right: "calc(25% - 50px)",
    };
  }
  console.log(isMobile)
  return (
  <div>{!isMobile && 
    <div className="showcaseSlideElement">
      <img className="showcaseImg" src={image} alt="pic" style={style.image} />
      <div className="showcaseImgShadow1" style={style.shadow1}></div>
      <div className="showcaseImgShadow2" style={style.shadow2}></div>
      <div className="showcaseFlex">
        <div className="showcaseDiagonalLeft" style={orientation==="left" ? {display:"none"} : {}}></div>
        <div
          className="showcaseElemTextContainer"
          style={style.textContainerStyle}
        >
          <h1 className="showcaseElemTitle">{title}</h1>
          <p className="showcaseElemContent">{content}</p>
        </div>
        <div className="showcaseDiagonal" style={orientation==="right" ? {display:"none"} : {}}></div>
      </div>
    </div>
}{isMobile && 
    <div className="showcaseSlideElement">
        <div className="showcaseMobileBackground" style={orientation === "right" ? {transform:"scaleX(-1)"} : {}}></div>
        <img className="showcaseImg" alt="pic" src={image}></img>
        <div className="showcaseImgShadow1" style={orientation === "right" ? {transform:"translateX(-20px)"}: {}}></div>
      <div className="showcaseImgShadow2" style={orientation === "right" ? {transform:"translateX(-40px)"}: {}}></div>
      <h1 className="showcaseElemTitle">{title}</h1>
      <p className="showcaseElemContent">{content}</p>
        </div>
}</div>)
    // {isMobile && <div>
    //     test
    // </div>}
  ;
}
