import React from "react";
import "./Program.css";
import Rocket from "./Rocket";
import ProgramTable from "./ProgramTable";
import Modal from "react-modal";
import data from "../../locales/ro.json"

export default function Program({ isMobile }) {
  const [day, setDay] = React.useState("7-9 Mai");
  const exampleArr = [
    [
      { name: "cedez psihic", startHour: "9:30", endHour: "10:30" },
      { name: "AAAAAAAAAAAAAAA", startHour: "10:30", endHour: "11:00" },
      { name: "sa mor eu", startHour: "11:00", endHour: "11:30" },
      { name: "michael jackson", startHour: "11:30", endHour: "12:00" },
      { name: "COSTEL vibes", startHour: "12:00", endHour: "13:00" },
      { name: "PARTY TIME", startHour: "13:00", endHour: "13:30" },
      { name: "dorm", startHour: "13:30", endHour: "15:00" },
    ],
    [
      { name: "Hear! The Siren Song Call of Death", startHour: "9:30", endHour: "10:32" },
      { name: "War Without Reason", startHour: "10:30", endHour: "11:00" },
      { name: "Suffering Leaves Suffering Leaves", startHour: "11:00", endHour: "11:30" },
      { name: "Do Robots Dream of Eternal Sleep?", startHour: "11:30", endHour: "12:00" },
      { name: "The World Looks Red", startHour: "12:00", endHour: "13:00" },
      { name: "The World Looks White", startHour: "13:00", endHour: "13:30" },
      { name: "dorm", startHour: "13:30", endHour: "15:00" },
    ],
    [
      { name: "Hear! The Siren Song Call of Death", startHour: "9:30", endHour: "10:32" },
      { name: "War Without Reason", startHour: "10:30", endHour: "11:00" },
      { name: "Suffering Leaves Suffering Leaves", startHour: "11:00", endHour: "11:30" },
      { name: "Do Robots Dream of Eternal Sleep?", startHour: "11:30", endHour: "12:00" },
      { name: "The World Looks Red", startHour: "12:00", endHour: "13:00" },
      { name: "The World Looks White", startHour: "13:00", endHour: "13:30" },
      { name: "dorm", startHour: "13:30", endHour: "15:00" },
    ],
    [
      { name: "Hear! The Siren Song Call of Death", startHour: "9:30", endHour: "10:32" },
      { name: "War Without Reason", startHour: "10:30", endHour: "11:00" },
      { name: "Suffering Leaves Suffering Leaves", startHour: "11:00", endHour: "11:30" },
      { name: "Do Robots Dream of Eternal Sleep?", startHour: "11:30", endHour: "12:00" },
      { name: "The World Looks Red", startHour: "12:00", endHour: "13:00" },
      { name: "The World Looks White", startHour: "13:00", endHour: "13:30" },
      { name: "dorm", startHour: "13:30", endHour: "15:00" },
    ],
    [
      { name: "Hear! The Siren Song Call of Death", startHour: "9:30", endHour: "10:32" },
      { name: "War Without Reason", startHour: "10:30", endHour: "11:00" },
      { name: "Suffering Leaves Suffering Leaves", startHour: "11:00", endHour: "11:30" },
      { name: "Do Robots Dream of Eternal Sleep?", startHour: "11:30", endHour: "12:00" },
      { name: "The World Looks Red", startHour: "12:00", endHour: "13:00" },
      { name: "The World Looks White", startHour: "13:00", endHour: "13:30" },
      { name: "dorm", startHour: "13:30", endHour: "15:00" },
    ],
  ];

  const [programQuery, setProgramQuery] = React.useState(1);
  const [isOpen, setIsOpen] = React.useState(false);
  const modalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      // position:"relative"
    },
    overlay: {zIndex:10}
  };

  return (
    <div className="programContainer" id="program">
      {isMobile && <Modal isOpen={isOpen} style={modalStyles} contentLabel="Program"><div className="modalContainer"><ProgramTable dayArr={data.program[programQuery]}/><button onClick={() => {setIsOpen(false)}} className="closeModal">Înapoi</button></div></Modal>}
      {!isMobile && (
        <div className="programTableContainer">
          <h2 className="programDay">{day}</h2>
          <ProgramTable dayArr={data.program[programQuery]} />
        </div>
      )}
      <Rocket
        funcs={[
          () => {

          },
          () => {
            setProgramQuery(0);
            setIsOpen(true)
            setDay("10 Mai")
          },
          () => {
            setProgramQuery(1);
            setIsOpen(true)
            setDay("11 Mai")
          },
          () => {
            setProgramQuery(2);
            setIsOpen(true)
            setDay("10 Mai")
          },
          () => {
            setProgramQuery(3);
            setIsOpen(true)
            setDay("11 Mai")
          },
        ]}
      />
    </div>
  );
}
