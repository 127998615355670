import React from "react";
import "./Partners.css";
import PartnerCard from "./PartnerCard";
import PartnersTitle from "./PartnersTitle";
import data from "../../locales/ro.json"

export default function Partners({isMobile}) {
  // const partnersArr = [
  //   {
  //     title: "anuali",
  //     size: "xs",
  //     color: "portocaliu",
  //     partners: [
  //       "https://i.imgur.com/EQpewgj.png",
  //       "https://i.imgur.com/EQpewgj.png",
  //       "https://i.imgur.com/EQpewgj.png",
  //     ],
  //   },
  //   {
  //     title: "bronze",
  //     color: "turcoaz",
  //     size: "m",
  //     partners: [
  //       "https://i.imgur.com/EQpewgj.png",
  //       "https://i.imgur.com/EQpewgj.png",
  //       "https://i.imgur.com/EQpewgj.png",
  //     ],
  //   },
  //   {
  //     title: "gold",
  //     color: "mov",
  //     size: "l",
  //     partners: [
  //       "https://i.imgur.com/EQpewgj.png",
  //       "https://i.imgur.com/EQpewgj.png",
  //       "https://i.imgur.com/EQpewgj.png",
  //     ],
  //   },
  // ];

  const partnersArr = data.partners;
  let index = 0;

  return (
    <div className="partnersContainer">
      {partnersArr.map((category) => {
        return (
          <div>
            <PartnersTitle title={category.title} color={category.color} />
            <div className="partnerCardsContainer">
              {category.partners.map((partner) => {
                console.log(category.size);
                index++;
                return <PartnerCard isMobile={isMobile} logo={partner.logo} size={category.size} link={partner.link}/>;
              })}
            </div>
          </div>
        );
      })}
      {/* <PartnersTitle title={"ANUALI"} color={"portocaliu"} />
      <PartnerCard logo={"https://i.imgur.com/EQpewgj.png"} size={"xs"} />
      <PartnerCard logo={"https://i.imgur.com/EQpewgj.png"} size={"xs"} />
      <PartnerCard logo={"https://i.imgur.com/EQpewgj.png"} size={"xs"} /> */}
    </div>
  );
}
