import React from "react";
import "./Rules.css";
import RuleComponent from "./RuleComponent";
import puzzleImage from "../../icons/Frame.png"
import data from "../../locales/ro.json"

export default function Rules() {
  const [selectedIndex, setSelectedIndex] = React.useState(-1);
  const rulesArr = data.rules;
  return (
    <div className="rulesContainer">
      <div className="rulesImgContainer">
        <img src={puzzleImage} alt="Join us now"></img>
        <button className="rulesJoinButton"><a href='https://forms.gle/sw2YkXgfS5c1Z2Qk9' target='_blank'>Înscrie-te</a></button>
      </div>
      <div className="rulesBoxContainer">
        <h1>Regulament</h1>
        {rulesArr.map((rule, index) => {
          return <RuleComponent
            header={rule.header}
            content={rule.content}
            index={index}
            onClickFcn={() => {
              setSelectedIndex((prevIndex) => {
                return prevIndex !== -1 ? -1 : index;
              });
            }}
            selectedIndex={selectedIndex}
          />;
        })}
      </div>
    </div>
  );
}
